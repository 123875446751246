import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import useSpace from './useSpace';

export default function useSpaceCodePageRedirection({ pathname }) {
  const { currentSpace } = useSpace();
  const [redirectCalled, setRedirectCalled] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const init = async () => {
      try {
        if (redirectCalled) return;

        const { spaceCode, ...queryParams } = router.query;

        // Waiting for updated space info to load before redirecting...
        if (Number(spaceCode) !== currentSpace.space_code) return;

        router.replace({
          pathname,
          query: { ...queryParams },
        });

        setRedirectCalled(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    if (router.isReady && currentSpace?.space_code) init();
  }, [router.isReady, currentSpace]);

  return null;
}
